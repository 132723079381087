/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */
var menuOpening = false;
(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

    $('*').on('click', function(){

        if(!menuOpening)
        {
            $('.mega-menu').slideUp();
        }

    });

    $(document).scroll(function() {
        var scroll = $(this).scrollTop();
        if (scroll >= 130) {
            $('.navbar-default').addClass('fixed');
            $('.logo-wrapper').addClass('visible');
            $('.inline-logo').removeClass('visible');
            $('.banner-text').css('margin-top', '71px');
        }
        else
        {
            $('.navbar-default').removeClass('fixed');
            $('.logo-wrapper').removeClass('visible');
            $('.inline-logo').addClass('visible');
            $('.banner-text').css('margin-top', '15px');
        }
    });

    // ADD SLIDEDOWN ANIMATION TO DROPDOWN //
    $('.dropdown').on('show.bs.dropdown', function(e){
        $(this).find('.dropdown-menu').first().stop(true, true).slideDown();
    });

    // ADD SLIDEUP ANIMATION TO DROPDOWN //
    $('.dropdown').on('hide.bs.dropdown', function(e){
        $(this).find('.dropdown-menu').first().stop(true, true).slideUp();
    });

    //Slides
    $(document).ready(function(){
        startTabs(0);
    });

    function startTabs(index)
    {
        var tabToShow = $('.content-wrapper.referenties .nav-tabs li:eq(' + index + ') a');

        if(tabToShow.length === 0)
        {
            index = 0;
            tabToShow = $('.content-wrapper.referenties .nav-tabs li:eq(' + index + ') a');
        }

        $('.content-wrapper.referenties .loader .bar').css('width', '0%');
        $(tabToShow).tab('show');

        $('.content-wrapper.referenties .loader .bar').animate({ width: '100%' }, 5500, 'linear', function(){
            startTabs(index+1);
        });
    }

  $('.mega-menu').appendTo('.navbar.navbar-default');

})(jQuery); // Fully reference jQuery after this point.

function toggleMenu(id)
{
    if(jQuery('.mega-menu[data-menu-id="' + id + '"]').length > 0) {
        jQuery('.mega-menu').hide();
        menuOpening = true;
        jQuery('.mega-menu[data-menu-id="' + id + '"]').slideToggle(400, 'swing', function () {
            menuOpening = false;
        });
    }
}
